<div (click)="onToggle()" class="group cursor-pointer">
  @if (label) {
    <div class="pl-3 pr-2 h-8 border rounded-full flex items-center gap-1 hover:border-blue-600">
      <span class="font-semibold">{{ label }}: </span>
      <div class="flex gap-0.5 items-center">
        <div class="max-w-[200px] truncate">
          {{ selectedUsersString ? selectedUsersString : ('common.all' | translate) }}
        </div>

        <i class="icon-wl-arrow-drop-down text-6"></i>
      </div>
    </div>
  } @else {
    <div
      class="w-full flex justify-between items-center px-2 py-1 rounded"
      [ngClass]="disabled ? '' : 'hover:bg-neutral-alpha-100A'">
      @if (usersSelected && usersSelected.length) {
        <div class="flex flex-wrap gap-2">
          @for (user of usersSelected; track $index) {
            <div class="flex items-center gap-1">
              <app-custom-avatar [imgId]="user?.avatar || ''" [name]="user.fullName"></app-custom-avatar>
              <span class="max-w-25 truncate" [ngClass]="isHighlightName ? 'font-medium' : ''">{{
                user.fullName
              }}</span>
            </div>
          }
        </div>
      } @else {
        <span class="text-neutral-alpha-500A">
          {{ 'common.empty' | translate }}
        </span>
        @if (!disabled) {
          <div class="h-5 w-5 flex items-center justify-center">
            <i class="icon-wl-arrow-drop-down text-6" [ngClass]="visible ? '' : 'group-hover:block hidden'"></i>
          </div>
        }
      }
    </div>
  }
</div>

<p-overlay #overlay [appendTo]="appendTo" [(visible)]="visible" contentStyleClass="rounded-md shadow bg-white w-full">
  <div class="h-11 flex items-center gap-2 border-b">
    <app-search-input
      inputClass="border-none !shadow-none"
      [debounceTime]="300"
      (onSearch)="onSearch($event)"></app-search-input>
  </div>
  <div
    class="w-full max-h-[200px] overflow-y-auto"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="onScroll()">
    @if (users.length) {
      @for (user of userSelects; track user.id) {
        <div class="flex px-3 hover:bg-neutral-200 py-2 gap-2">
          <p-checkbox [(ngModel)]="usersSelected" (ngModelChange)="onUsersSelectedChange()" [value]="user"></p-checkbox>
          <app-custom-avatar [imgId]="user?.avatar ?? ''" [name]="user?.fullName ?? ''"></app-custom-avatar>
          <div class="cursor-pointer w-full truncate">
            {{ user.fullName }}
          </div>
        </div>
      }
    } @else {
      <div class="flex items-center justify-center flex-col gap-2 py-4">
        <div class="h-9 w-9 flex items-center justify-center bg-neutral-alpha-200A rounded-full">
          <i class="icon-wl-user text-6"></i>
        </div>

        <div>No users to display</div>
      </div>
    }
  </div>
</p-overlay>
