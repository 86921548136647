import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UserDetail, UserSelect } from '@core/models/interfaces/user';
import { UserService } from '@core/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { CheckboxModule } from 'primeng/checkbox';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayModule } from 'primeng/overlay';

@Component({
  selector: 'app-user-multi-select-dropdown',
  templateUrl: './user-multi-select-dropdown.component.html',
  styleUrl: './user-multi-select-dropdown.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    HttpClientModule,
    InputTextModule,
    OverlayModule,
    InfiniteScrollDirective,
    IconFieldModule,
    InputIconModule,
    FloatLabelModule,
    CheckboxModule,
    CustomAvatarComponent,
    SearchInputComponent,
    TranslateModule
  ]
})
export class UserMultiSelectDropdownComponent {
  @Input() appendTo: 'body' | HTMLElement | undefined;
  @Input() isHighlightName: boolean = false;
  @Input({ required: true }) usersSelected: UserSelect[] = [];
  @Input({ required: true }) totalUsers: number = 0;
  @Input({ required: true }) users: UserDetail[] = [];
  @Input() label: string = '';
  @Input() disabled: boolean = false;

  @Output() usersSelectedChange = new EventEmitter<UserSelect[]>();

  visible = false;
  keyword = '';
  page = 0;

  constructor(private userService: UserService) {}

  get selectedUsersString() {
    return this.usersSelected.map(user => user.fullName).join(', ');
  }

  get userSelects(): UserSelect[] {
    return this.users.map(item => ({
      id: item.id,
      fullName: item.fullName,
      avatar: item.avatar || ''
    }));
  }

  onScroll() {
    if (this.totalUsers > this.users.length) {
      this.page++;
      this.fetchUsers('SCROLL');
    }
  }

  onUsersSelectedChange() {
    this.usersSelectedChange.emit(this.usersSelected);
  }

  fetchUsers(type: 'SCROLL' | 'SEARCH') {
    this.userService
      .getUsers({
        page: this.page,
        keyword: this.keyword
      })
      .subscribe({
        next: res => {
          this.totalUsers = res.data.total;
          if (type === 'SCROLL') {
            this.users = [...this.users, ...res.data.content];
          }
          if (type === 'SEARCH') {
            this.users = res.data.content;
          }
        }
      });
  }

  onSearch(keyword: string) {
    this.keyword = keyword;
    this.page = 0;
    this.fetchUsers('SEARCH');
  }

  onToggle() {
    if (!this.disabled) {
      this.visible = !this.visible;
    }
  }
}
