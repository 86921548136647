import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { LeadStatus, LeadStatusCreate, LeadStatusStatistic } from '@core/models/interfaces/lead';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadStatusService {
  private baseUrl = `${environment.API_URL}/crm/lead-status`;

  constructor(private httpClient: HttpClient) {}

  getLeadStatusById(id: string) {
    return this.httpClient.get<ResponseApi<LeadStatus>>(`${this.baseUrl}/${id}`);
  }

  updateLeadStatusById(id: string, leadStatus: LeadStatusCreate) {
    return this.httpClient.put(`${this.baseUrl}/${id}`, leadStatus);
  }

  deleteLeadStatusById(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  getLeadStatuses(params?: ApiBasicParams) {
    return this.httpClient.get<ResponseListApi<LeadStatus>>(this.baseUrl, { params: { ...params } });
  }

  getLeadStatusStatistic() {
    return this.httpClient.get<ResponseApi<LeadStatusStatistic[]>>(`${this.baseUrl}/statistics`);
  }

  createLeadStatus(leadStatus: LeadStatusCreate) {
    return this.httpClient.post(this.baseUrl, leadStatus);
  }
}
