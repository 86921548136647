import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommentCreate, CommentDetails, GetCommentParams } from '@core/models/interfaces/comment';
import { ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private baseUrl = `${environment.API_URL}/crm/comments`;

  constructor(private httpClient: HttpClient) {}

  createComment(body: CommentCreate) {
    return this.httpClient.post<ResponseApi<CommentDetails>>(this.baseUrl, body);
  }

  getCommentById(id: string) {
    return this.httpClient.get<ResponseApi<CommentDetails>>(`${this.baseUrl}/${id}`);
  }

  updateCommentById(id: string, content: string) {
    return this.httpClient.put<ResponseApi<CommentDetails>>(`${this.baseUrl}/${id}`, {
      content: content
    });
  }

  pinCommentById(id: string, isPin: boolean) {
    return this.httpClient.put<ResponseApi<CommentDetails>>(`${this.baseUrl}/${id}/pin?pin=${isPin}`, {});
  }

  getComments(params: GetCommentParams) {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('objectId', params.objectId);
    if (params.keyword !== undefined) {
      queryParams = queryParams.append('keyword', params.keyword);
    }
    if (params.createdFrom !== undefined) {
      queryParams = queryParams.append('createdFrom', params.createdFrom);
    }
    if (params.createdTo !== undefined) {
      queryParams = queryParams.append('createdTo', params.createdTo);
    }
    if (params.page !== undefined) {
      queryParams = queryParams.append('page', params.page);
    }
    if (params.size !== undefined) {
      queryParams = queryParams.append('size', params.size);
    }
    if (params.sorts !== undefined) {
      queryParams = queryParams.append('sorts', params.sorts);
    }

    return this.httpClient.get<ResponseListApi<CommentDetails>>(this.baseUrl, {
      params: queryParams
    });
  }

  deleteComment(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }
}
